import React from 'react'
import Container from 'react-bootstrap/Container'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { useQuery } from 'react-apollo-hooks'

import Title from '../components/Title'
import Layout from '../components/Layout'
import Heading from '../components/Heading'
import DeviceStatus from './DeviceStatus'
import DeviceHeights from './DeviceHeights'
import DeviceBatteries from './DeviceBatteries'
import DeviceAlarms from './DeviceAlarms'
import DeviceStatusLog from './DeviceStatusLog'
import DEVICE_CURRENT_STATE from '../graphql/queries/deviceCurrentState'

import moment from 'moment'

const device = {
  machineId: '995W_1372',
  type: '995W',
  isOffline: false,
  name: 'Ponte 1',
  user: 'FG7632S',
  token: 'dfd2db9b-eb52-4a10-9d29-0da5957ce746',
  lastContactDateTime: '2019-09-09 12:34:45',
  current: {
    status: 'PAUSA',
    mode: 'FUNZIONAMENTO PER GRUPPO',
    height1: 10,
    height2: 10,
    height3: 11,
    height4: 10,
    battery1: 56,
    battery2: 58,
    battery3: 43,
    battery4: 45,
    alarm1: 101,
    alarm2: 101,
    alarm3: 0,
    alarm4: 0,
    fault1: 1026,
    fault2: 0,
    fault3: 0,
    fault4: 0,
  },
  log: {
    statuses: [
      {
        date1: '2019-07-01 09:00:23',
        date2: '2019-07-15 12:17:09',
        value: 'PAUSA',
      },
      {
        date1: '2019-07-15 12:17:09',
        date2: '2019-07-15 12:20:01',
        value: 'FASE DI SALITA IN CORSO',
      },
      {
        date1: '2019-07-15 12:20:01',
        date2: '2019-07-16 17:00:00',
        value: 'PAUSA',
      },
      {
        date1: '2019-07-16 17:00:00',
        date2: '2019-07-16 17:10:00',
        value: 'FASE DI DISCESA IN CORSO',
      },
      {
        date1: '2019-07-16 17:10:00',
        date2: '2019-07-31 12:00:00',
        value: 'PAUSA',
      },
    ],
    modes: [
      {
        date1: '2019-07-01 09:00:23',
        date2: '2019-07-15 12:20:01',
        value: 'FUNZIONAMENTO PER GRUPPO',
      },
      {
        date1: '2019-07-15 12:20:01',
        date2: '2019-07-16 17:00:00',
        value: 'FUNZIONAMENTO SINGOLO',
      },
      {
        date1: '2019-07-16 17:00:00',
        date2: '2019-07-31 12:00:00',
        value: 'FUNZIONAMENTO PER GRUPPO',
      },
    ],
    heights: [1, 2, 3, 4].map(i => {
      let value = 0
      const now = moment()
      const first = moment().subtract(3, 'months')
      let date = first
      let values = []
      while (date.isBefore(now)) {
        date = date.add(6, 'minutes')
        const delta = Math.floor(Math.random() * (2 - -2 + 1)) + -2
        value = Math.floor(value + delta)
        values.push({
          date: date.format('YYYY-MM-DD HH:mm:ss'),
          value,
        })
      }
      return {
        name: `height${i}`,
        values,
      }
    }),
    batteries: [1, 2, 3, 4, 5, 6, 7, 8].map(i => {
      let value = 0
      const now = moment()
      const first = moment().subtract(3, 'months')
      let date = first
      let values = []
      while (date.isBefore(now)) {
        date = date.add(6, 'minutes')
        const delta = Math.floor(Math.random() * (2 - -2 + 1)) + -2
        value = Math.floor(value + delta)
        values.push({
          date: date.format('YYYY-MM-DD HH:mm:ss'),
          value,
        })
      }
      return {
        name: `battery${i}`,
        values,
      }
    }),
    alarms: [
      {
        name: 'alarm1',
        values: [
          { date1: '2019-07-12 12:05:00', date2: '2019-07-12 12:15:00', value: 101 },
          { date1: '2019-07-01 19:05:00', date2: '2019-07-01 19:20:00', value: 202 },
          { date1: '2019-07-06 05:05:00', date2: '2019-07-06 06:20:00', value: 291 },
          { date1: '2019-07-06 19:05:00', date2: '2019-07-06 19:20:00', value: 400 },
          { date1: '2019-07-29 08:05:00', date2: '2019-07-29 13:00:00', value: 210 },
          { date1: '2019-07-30 08:05:00', date2: '2019-07-30 13:00:00', value: 1020 },
          { date1: '2019-08-01 08:05:00', date2: '2019-08-01 13:00:00', value: 7012 },
        ],
      },
      {
        name: 'alarm2',
        values: [
          { date1: '2019-07-12 12:05:00', date2: '2019-07-12 12:15:00', value: 3210 },
          { date1: '2019-06-24 10:00:00', date2: '2019-06-24 12:05:00', value: 4512 },
        ],
      },
      {
        name: 'fault1',
        values: [{ date1: '2019-07-30 10:00:00', date2: '2019-07-30 23:00:00', value: 10232 }],
      },
      {
        name: 'alarm4',
        values: [],
      },
      {
        name: 'alarm5',
        values: [],
      },
      {
        name: 'alarm6',
        values: [],
      },
      {
        name: 'alarm7',
        values: [],
      },
      {
        name: 'alarm8',
        values: [],
      },
    ],
  },
}

const DeviceData = ({ device }) => (
  <div className="device-data">
    <span>Type: {device.deviceType.name}</span>&nbsp;•&nbsp;
    {device.isOffline && (
      <React.Fragment>
        <span>OFFLINE</span>&nbsp;•&nbsp;
      </React.Fragment>
    )}
    <span>{device.queuedTasksNumber} tasks</span>&nbsp;•&nbsp;
    <span>User: {device.user.username} ({device.user.name})</span>&nbsp;•&nbsp;
    <span>Token: {device.token}</span>
  </div>
)

const Device = ({ match: { params } }) => {
  const id = parseInt(params.id, 10)

  const { data, error, loading } = useQuery(DEVICE_CURRENT_STATE, { variables: { id } })

  const device = data && data.device

  return (
    <Layout>
      <style type="text/css">
        {`
        .device-data {
          margin-top: -26px;
          margin-bottom: 30px;
          font-size: 11px;
          text-transform: uppercase;
          color: #999;
        }
        .tab-content {
          padding: 30px 30px;
          background-color: #fff;
          border-bottom: 1px solid #dee2e6;
          border-left: 1px solid #dee2e6;
          border-right: 1px solid #dee2e6;
        }
        `}
      </style>
      <Container>
        {(loading || error) && (
          <div className="mt-5">
            {loading && <span>Loading device...</span>}
            {error && <span>Device not found</span>}
          </div>
        )}
        {device && (
          <div className="device-detail">
            <Title heading={<Heading title={`${device.machineId} "${device.name}"`} />} />
            <DeviceData device={device} />
            
            <Tabs defaultActiveKey="status" mountOnEnter>
              <Tab eventKey="status" title="Current State">
                <DeviceStatus device={device} />
              </Tab>
              <Tab eventKey="heights" title="Heights Log">
                <DeviceHeights deviceId={device.id} deviceType={device.deviceType.name} />
              </Tab>
              <Tab eventKey="batteries" title="Batteries Log" disabled={device.deviceType.name !== '995W'}>
                <DeviceBatteries deviceId={device.id} />
              </Tab>
              <Tab eventKey="alarms" title="Alarms Log">
                <DeviceAlarms deviceId={device.id} />
              </Tab>
              <Tab eventKey="status_log" title="Status Log" disabled={device.deviceType.name !== '995W'}>
                <DeviceStatusLog deviceId={device.id} />
              </Tab>
            </Tabs>
          </div>
        )}
      </Container>
    </Layout>
  )
}

export default Device
