import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-apollo-hooks'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

// Query
import DEVICE_TYPES from '../../graphql/queries/deviceTypes'
import USERS from '../../graphql/queries/users'

const btnId = {
  fontSize: 10,
  marginLeft: 10,
}

const ModalDevice = ({ isEdit, show, onCancel, onSend, values }) => {
  const {
    data: deviceTypes,
    error: errorDeviceTypes,
    loading: loadingDeviceTypes,
  } = useQuery(DEVICE_TYPES)

  const {
    data: usersData,
    error: errorUsers,
    loading: loadingUsers,
  } = useQuery(USERS)

  const [formValues, setFormValues] = useState(values)

  useEffect(() => {
    setFormValues(values)
  }, [values])

  const renderDeviceTypes = () => {
    if (loadingDeviceTypes || errorDeviceTypes) {
      return null
    }
    const options = deviceTypes.deviceTypes.nodes
    return options.map(option => (
      <option key={option.id} value={option.id}>
        {option.name}
      </option>
    ))
  }

  const renderUsers = () => {
    if (loadingUsers || errorUsers) {
      return null
    }
    const options = usersData.users.nodes
    return options.map(option => (
      <option key={option.id} value={option.id}>
        {option.username}
      </option>
    ))
  }

  const handleChangeInput = fieldName => event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const parsedValue =
      fieldName === 'deviceTypeId' || fieldName === 'userId'
        ? parseInt(value)
        : value
    let overwriteUserId = {}
    if (fieldName === 'createUserFlag') {
      overwriteUserId = {
        userId: null,
      }
    }
    setFormValues({
      ...formValues,
      [fieldName]: parsedValue,
      ...overwriteUserId,
    })
  }

  const handleSetNameAsId = () => {
    setFormValues({
      ...formValues,
      name: formValues.machineId,
    })
  }

  const sendDevice = event => {
    event.preventDefault()
    const deviceWithoutId = { ...formValues }
    delete deviceWithoutId.id
    isEdit
      ? onSend({ variables: { device: deviceWithoutId, id: formValues.id } })
      : onSend({
          variables: {
            device: deviceWithoutId,
          },
        })
    onCancel()
  }

  return (
    <Modal show={show} onHide={onCancel}>
      <Form onSubmit={sendDevice}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEdit ? `Edit device ${formValues.machineId}` : 'Add new device'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Type</Form.Label>
            <Form.Control
              id="deviceTypeId"
              as="select"
              value={formValues.deviceTypeId}
              onChange={handleChangeInput('deviceTypeId')}
            >
              {renderDeviceTypes()}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Machine Id</Form.Label>
            <Form.Control
              id="machineId"
              type="text"
              value={formValues.machineId}
              onChange={handleChangeInput('machineId')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Name{' '}
              <Button
                variant="primary"
                size="sm"
                style={btnId}
                onClick={handleSetNameAsId}
              >
                = Id
              </Button>
            </Form.Label>
            <Form.Control
              id="name"
              type="text"
              value={formValues.name}
              onChange={handleChangeInput('name')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Token</Form.Label>
            <Form.Control
              id="token"
              type="text"
              value={formValues.token}
              onChange={handleChangeInput('token')}
            />
          </Form.Group>
          {!isEdit && (
            <Form.Group controlId="cbCreateUser">
              <Form.Check
                id="createUserFlag"
                type="checkbox"
                className="mb-2"
                label="Create User"
                checked={formValues.createUserFlag}
                onChange={handleChangeInput('createUserFlag')}
              />
            </Form.Group>
          )}
          {formValues.createUserFlag && !isEdit ? (
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                id="password"
                type="password"
                value={formValues.userPassword}
                onChange={handleChangeInput('userPassword')}
              />
            </Form.Group>
          ) : (
            <Form.Group>
              <Form.Label>User</Form.Label>
              <Form.Control
                id="userId"
                as="select"
                value={formValues.userId || ''}
                onChange={handleChangeInput('userId')}
              >
                {renderUsers()}
              </Form.Control>
            </Form.Group>
          )}
          <Form.Group controlId="cbIsOffline">
            <Form.Check
              id="isOffline"
              className="mb-2"
              label="Device Offline"
              checked={formValues.isOffline}
              onChange={handleChangeInput('isOffline')}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            {isEdit ? 'Edit device' : 'Create device'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ModalDevice
