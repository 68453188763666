import { gql } from 'apollo-boost'

const DEVICE_ALARMS = gql`
  query getStats($deviceId: Int!, $from: DateTime!, $to: DateTime!) {
    getStats(deviceId: $deviceId, from: $from, to: $to, fields: [
      { name: "alarm", type: RANGE },
      { name: "alarm1", type: RANGE },
      { name: "alarm2", type: RANGE },
      { name: "alarm3", type: RANGE },
      { name: "alarm4", type: RANGE },
      { name: "alarm5", type: RANGE },
      { name: "alarm6", type: RANGE },
      { name: "alarm7", type: RANGE },
      { name: "alarm8", type: RANGE },
      { name: "fault1", type: RANGE },
      { name: "fault2", type: RANGE },
      { name: "fault3", type: RANGE },
      { name: "fault4", type: RANGE },
      { name: "fault5", type: RANGE },
      { name: "fault6", type: RANGE },
      { name: "fault7", type: RANGE },
      { name: "fault8", type: RANGE }
    ]) {
      name
      values {
        date1,
        date2,
        value
      }
    }
  }
`
export default DEVICE_ALARMS
