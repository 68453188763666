import { gql } from 'apollo-boost'

const ME = gql`
  query me {
    me {
      id
      role
      username
      name
      companyName
      address1
      address2
      city
      country
      zipCode
      phone
      email
      userFlag
    }
  }
`
export default ME
