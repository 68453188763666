import { gql } from 'apollo-boost'

const TASKS = gql`
  query tasks($from: DateTime, $to: DateTime) {
    tasks(limit: 10000, where: { createdAt_gte: $from, createdAt_lte: $to }) {
      nodes {
        id
        deviceId
        plate
        description
        device {
          machineId
          name
          deviceFlag
        }
        status
        notes
        description
        maxValue
        lockDisable
        startAt
        finishAt
      }
    }
  }
`
export default TASKS
