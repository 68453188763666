import { gql } from 'apollo-boost'

const CREATE_USER = gql`
  mutation createUser($user: CreateUser!) {
    createUser(user: $user) {
      id
      role
      name
      companyName
      username
      country
      city
      address1
      address2
      phone
      zipCode
      email
      devices {
        id
      }
      password
    }
  }
`
export default CREATE_USER
