import { gql } from 'apollo-boost'

const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      me {
        id
        role
        username
        name
        companyName
      }
    }
  }
`
export default LOGIN
