import React from 'react'
import moment from 'moment'

import { useQuery } from 'react-apollo-hooks'

import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

import DEVICE_BATTERIES from '../graphql/queries/deviceBatteries'
import DEVICE_ALARMS from '../graphql/queries/deviceAlarms'

const DeviceBatteries = ({ deviceId }) => {
  const from = moment()
    .subtract(2, 'months')
    .format('YYYY-MM-DD')
  const to = moment().format('YYYY-MM-DD')

  const { data: batteriesData, error: batteriesError, loading: batteriesLoading } = useQuery(DEVICE_BATTERIES, { variables: { deviceId, from, to } })

  const { data: alarmsData, error: alarmsError, loading: alarmsLoading } = useQuery(DEVICE_ALARMS, { variables: { deviceId, from, to } })

  const series =
    batteriesLoading || batteriesError || !batteriesData
      ? null
      : batteriesData.getStats.map(bs => ({
          name: bs.name,
          data: bs.values.map(b => [moment(b.date1).valueOf(), parseInt(b.value, 10)]),
        }))

  const plotBands =
    alarmsLoading || alarmsError || !alarmsData
      ? null
      : alarmsData.getStats.reduce((acc, alarmType) => {
          const alarmsOfType = alarmType.values.filter(alarm => alarm.value !== '0' && alarm.value !== 'false' && alarm.value !== null).map(alarm => ({
            color: 'hsla(36,100%,50%,0.4)',
            from: moment(alarm.date1, 'YYYY-MM-DD HH:mm:ss', true).valueOf(),
            to: moment(alarm.date2, 'YYYY-MM-DD HH:mm:ss', true).valueOf(),
            label: {
              text: `${alarm.value}`,
              style: {
                color: 'hsl(36,100%,30%)',
              },
            },
          }))

          return [...acc, ...alarmsOfType]
        }, [])

  const options = {
    rangeSelector: {
      selected: 0,
      buttons: [
        {
          type: 'day',
          count: 1,
          text: '1d',
        },
        {
          type: 'month',
          count: 1,
          text: '1m',
        },
        {
          type: 'all',
          text: 'All',
        },
      ],
    },
    xAxis: {
      type: 'datetime',
      plotBands,
    },
    yAxis: [
      {
        title: {
          text: 'Batteries',
        },
        lineWidth: 2,
      },
    ],
    series: series,
  }

  return (
    <div>
      <h4 style={{ textTransform: 'uppercase', fontSize: 16, padding: '4px 10px', backgroundColor: '#f0f0f0', borderRadius: 2, marginBottom: 10 }}>
        Batteries: Last 2 months log
      </h4>
      {series && plotBands && <HighchartsReact highcharts={Highcharts} constructorType={'stockChart'} options={options} />}
    </div>
  )
}

export default DeviceBatteries
