import React from 'react'
import Table from 'react-bootstrap/Table'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Alert from 'react-bootstrap/Alert'
import Badge from 'react-bootstrap/Badge'
import moment from 'moment'

const DeviceStatus = ({ device }) => {
  const alarms = []
  const faults = []

  if (device.type === '995W') {
    ;[1, 2, 3, 4, 5, 6, 7, 8].forEach(i => {
      const alarm = device.currentState[`alarm${i}`]
      if (alarm) {
        alarms.push({ label: `alarm${i}`, value: alarm })
      }
    })
    ;[1, 2, 3, 4, 5, 6, 7, 8].forEach(i => {
      const fault = device.currentState[`fault${i}`]
      if (fault) {
        faults.push({ label: `fault${i}`, value: fault })
      }
    })
  }

  return (
    <div>
      <style />

      <Row>
        <Col sm="8">
          <h4
            style={{ textTransform: 'uppercase', fontSize: 16, padding: '4px 10px', backgroundColor: '#f0f0f0', borderRadius: 2, marginBottom: 30 }}
          >
            {device.deviceType.name==='995W' ?
              <span>{device.currentState.status} / {device.currentState.mode} </span>
             :
              <span>Lift on the ground: {device.fcd ? 'Yes' : 'No'}</span>
             }
            
            <span style={{ marginLeft: 10 }}>
              <small>{moment(device.lastContactDateTime, 'YYYY-MM-DD HH:mm:ss', true).format('DD/MM/YYYY HH:mm')}</small>
            </span>
          </h4>

          <div>
            <h6>HEIGHTS</h6>
            <Table size="sm" bordered className="mb-4">
              <tbody>
                <tr>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map(i => (
                    <td style={{ width: '12.5%', textAlign: 'center' }} key={i}>
                      {device.currentState[`height${i}`]}
                    </td>
                  ))}
                </tr>
              </tbody>
            </Table>
          </div>
          <div>
            <h6>BATTERIES</h6>
            <Table size="sm" bordered>
              <tbody>
                <tr>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map(i => (
                    <td style={{ width: '12.5%', textAlign: 'center' }} key={i}>
                      {device.currentState[`battery${i}`]}
                    </td>
                  ))}
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
        {device.deviceType === '995W' ?
        <Col sm="4">
          {alarms.length > 0 || faults.length > 0 ? (
            <Alert variant="danger" style={{ textTransform: 'uppercase' }}>
              <h6>Active alarms and faults</h6>
              {alarms.map(a => (
                <Badge variant="danger" key={a.label} className="mr-2">
                  {a.label}: {a.value}
                </Badge>
              ))}
              {faults.map(f => (
                <Badge variant="danger" key={f.label} className="mr-2">
                  {f.label}: {f.value}
                </Badge>
              ))}
            </Alert>
          ) : (
            <Alert variant="success">No active alarms</Alert>
          )}
        </Col>
        :
        <Col sm="4">
          {device.currentState.alarm ? (
            <Alert variant="danger" style={{ textTransform: 'uppercase' }}>
              <h6>Alarm active</h6>
            </Alert>
          ) : (
            <Alert variant="success">No active alarms</Alert>
          )}
        </Col>
          }
      </Row>
    </div>
  )
}

export default DeviceStatus
