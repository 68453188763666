import ApolloClient from 'apollo-boost'
import { GRAPHQL_API_URI } from '../config'

const apolloClient = new ApolloClient({
  uri: GRAPHQL_API_URI,
  request: async operation => {
    const token = localStorage.getItem('omcn_iot_token')
    operation.setContext({
      headers: token
        ? {
            authorization: `Bearer ${token}`,
          }
        : {},
    })
  },
  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      console.log(graphQLErrors)
    }
    if (networkError) {
      console.log('Network error', networkError)
    }
  },
})

apolloClient.defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
  },
  query: {
    fetchPolicy: 'cache-and-network',
  },
}

export default apolloClient
