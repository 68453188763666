import { gql } from 'apollo-boost'

const UPDATE_USER = gql`
  mutation updateUser($user: UpdateUser!, $id: Int!) {
    updateUser(user: $user, id: $id) {
      id
      role
      name
      companyName
      username
      country
      city
      address1
      address2
      phone
      zipCode
      email
      devices {
        id
      }
      password
    }
  }
`
export default UPDATE_USER
