import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import moment from 'moment'

const FromToDate = ({ from, to, changeDate }) => {
  return (
    <div className="d-flex justify-space-between from-to-date-filter">
      <Form.Control
        id="from"
        type="date"
        name="from"
        style={{ marginRight: 15 }}
        value={moment(from).format('YYYY-MM-DD')}
        onChange={changeDate}
      />
      <Form.Control
        id="to"
        type="date"
        name="to"
        value={moment(to).format('YYYY-MM-DD')}
        onChange={changeDate}
      />
    </div>
  )
}

export default FromToDate
