import { gql } from 'apollo-boost'

const DEVICES = gql`
  query devices {
    devices(
      limit: 1
      where: { deviceFlag_not: 0 }
    ) {
      totalCount
    }
  }
`
export default DEVICES
