import { gql } from 'apollo-boost'

const DEVICE_STATUS_LOG = gql`
  query getStats($deviceId: Int!, $from: DateTime!, $to: DateTime!) {
    getStats(deviceId: $deviceId, from: $from, to: $to, fields: [
      { name: "status", type: RANGE },
      { name: "mode", type: RANGE },
    ]) {
      name
      values {
        date1,
        date2,
        value
      }
    }
  }
`
export default DEVICE_STATUS_LOG
