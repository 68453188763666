import React, { useState, useContext, useCallback } from 'react'
import { useQuery, useMutation } from 'react-apollo-hooks'
import Container from 'react-bootstrap/Container'
import uuidv4 from 'uuid/v4'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import { FiEdit, FiTrash2 } from 'react-icons/fi'
import ReactPaginate from 'react-paginate'
import { debounce } from 'debounce'

import useSearch from '../customHooks/useSearch'

import DEVICES from '../graphql/queries/devices'

// Mutation
import CREATE_DEVICE from '../graphql/mutations/createDevice'
import UPDATE_DEVICE_ADMIN from '../graphql/mutations/updateDeviceAdmin'
import UPDATE_DEVICE_USER from '../graphql/mutations/updateDeviceUser'
import ADD_DEVICE from '../graphql/mutations/addDevice'

// Context
import AuthContext from '../context/authContext'

import Title from '../components/Title'
import Search from '../components/Search'
import Heading from '../components/Heading'
import CreateButton from '../components/CreateButton'
import DeviceThumbnail from '../components/Device/DeviceThumbnail'

import Layout from '../components/Layout'
import ModalDevice from '../components/Device/ModalDevice'
import ModalDeviceUser from '../components/Device/ModalDeviceUser'

const devices = [
  {
    deviceTypeId: 1,
    machineId: '995W_1273',
    username: 'AF4K3J',
    status: 'IDLE',
    lastContactDateTime: '2019-07-30 09:12',
  },
  {
    deviceTypeId: 1,
    machineId: '995W_18732',
    username: 'KVM45S',
    status: 'RUN',
    lastContactDateTime: '2019-07-30 09:03',
  },
  {
    deviceTypeId: 1,
    machineId: '862S_6522',
    username: '8SFNN3',
    status: '-',
    lastContactDateTime: '',
  },
  {
    deviceTypeId: 2,
    machineId: '275K_8721',
    username: 'XQDZP4',
    status: 'IDLE',
    lastContactDateTime: '2019-07-30 19:55',
  },
  {
    deviceTypeId: 2,
    machineId: '275K_6621',
    username: 'HBB4WE',
    status: 'ERROR',
    lastContactDateTime: '2019-07-30 09:23',
  },
]

const initialCreateDevice = {
  deviceTypeId: 1,
  machineId: '',
  name: '',
  token: '',
  userId: 3,
  createUserFlag: false,
  userPassword: '',
  isOffline: false,
}

const initialAddDeviceUser = {
  machineId: '',
  name: '',
  token: '',
  isOffline: false,
}

const Devices = () => {
  // const [keywords, setKeywords, match] = useSearch(['name', 'machineId', 'user.username'])
  const [keywordUI, setKeywordUI] = useState('')
  const [keywordSearch, setKeywordSearch] = useState('')
  const PAGE_LIMIT = 12
  const [offset, setOffset] = useState(0)
  const { data, error, loading } = useQuery(DEVICES, {
    variables: { limit: PAGE_LIMIT, offset, keyword: keywordSearch },
  })

  const auth = useContext(AuthContext)

  const [show, setShow] = useState(false)
  const [device, setDevice] = useState(initialCreateDevice)

  const [addDeviceUser, setAddDeviceUser] = useState(initialAddDeviceUser)

  const [createDevice, { error: errorCreateDevice, data: dataCreateDevice }] = useMutation(
    CREATE_DEVICE,
    {
      update: (cache, { data: { createDevice } }) => {
        const { devices } = cache.readQuery({ query: DEVICES })
        const newDevices = {
          ...devices,
          nodes: [...devices.nodes, createDevice],
        }
        cache.writeQuery({
          query: DEVICES,
          data: { devices: newDevices },
        })
      },
    }
  )

  const [updateDeviceAdmin, { error: errorUpdateDeviceAdmin, data: dataUpdateDeviceAdmin }] =
    useMutation(UPDATE_DEVICE_ADMIN)

  const [addDevice, { error: errorAddDevice, data: dataAddDevice }] = useMutation(ADD_DEVICE, {
    update: (cache, { data: { addDevice } }) => {
      const { devices } = cache.readQuery({ query: DEVICES })
      const isExisting = devices.nodes.find((d) => d.id === addDevice.id)

      if (!isExisting) {
        const newDevices = {
          ...devices,
          nodes: [...devices.nodes, addDevice],
        }
        cache.writeQuery({
          query: DEVICES,
          data: { devices: newDevices },
        })
      }
    },
  })

  const [updateDeviceUser, { error: errorUpdateDeviceUser, data: dataUpdateDeviceUser }] =
    useMutation(UPDATE_DEVICE_USER)

  const getMutation = () => {
    if (!device.machineId) {
      return createDevice
    } else {
      return updateDeviceAdmin
    }
  }

  const getMutationUser = () => {
    if (!addDeviceUser.id) {
      return addDevice
    } else {
      return updateDeviceUser
    }
  }

  const setKeywordSearchDebounced = useCallback(debounce(setKeywordSearch, 800), [])

  const handleSetKeywords = (event) => {
    setKeywordUI(event.target.value)
    setKeywordSearchDebounced(event.target.value)
  }

  const handleShow = () => setShow(true)
  const handleClose = () => {
    setShow(false)
    setDevice(initialCreateDevice)
  }

  // const handleEditDevice = device => event => {
  //   const params = {
  //     id: device.id,
  //     deviceTypeId: device.deviceType.id,
  //     machineId: device.machineId,
  //     name: device.name,
  //     token: device.token,
  //     userId: device.user.id,
  //     isOffline: device.isOffline,
  //   }
  //   event.stopPropagation()
  //   event.preventDefault()
  //   handleShow()
  //   setDevice(params)
  // }

  const handleEditDevice = (device) => (event) => {
    event.stopPropagation()
    event.preventDefault()
    handleShow()

    if (auth.user.role === 'ADMIN') {
      const params = {
        id: device.id,
        deviceTypeId: device.deviceType.id,
        machineId: device.machineId,
        name: device.name,
        token: device.token,
        userId: device.user.id,
        isOffline: device.isOffline,
      }
      setDevice(params)
    } else {
      const params = {
        id: device.id,
        machineId: device.machineId,
        name: device.name,
        token: device.token,
        isOffline: device.isOffline,
      }
      setAddDeviceUser(params)
    }
  }

  const openModalDevice = () => {
    handleShow()
    if (auth.user.role === 'ADMIN') {
      setDevice({
        ...initialCreateDevice,
        token: uuidv4(),
      })
    } else {
      setAddDeviceUser({
        ...initialAddDeviceUser,
      })
    }
  }

  const renderDevices = () => {
    if (loading) {
      return <div>Caricamento devices in corso...</div>
    }

    if (error) {
      return <div>Errore durante il caricamento dei devices</div>
    }
    return data.devices.nodes.map((d) => (
      <Col sm="3" key={d.id}>
        <DeviceThumbnail device={d} onEdit={handleEditDevice(d)} />
      </Col>
    ))
  }

  const handleChangeOffset = ({ selected }) => {
    setOffset(PAGE_LIMIT * selected)
  }

  return (
    <Layout>
      <Container>
        <Title
          heading={<Heading title="OMCN Device Monitoring" />}
          search={<Search keywords={keywordUI} onChange={handleSetKeywords} />}
          button={<CreateButton item="Device" onClick={openModalDevice} />}
        />
      </Container>

      {auth.user.role === 'ADMIN' ? (
        <ModalDevice
          show={show}
          onCancel={handleClose}
          onSend={getMutation()}
          isEdit={!!device.machineId}
          values={device || initialCreateDevice} // da query apollo
        />
      ) : (
        <ModalDeviceUser
          show={show}
          onCancel={handleClose}
          onSend={getMutationUser()}
          isEdit={!!addDeviceUser.machineId}
          values={addDeviceUser || initialAddDeviceUser}
        />
      )}

      <Container>
        <Row>{renderDevices()}</Row>
        {data && (
          <ReactPaginate
            previousLabel={'« precedente'}
            nextLabel={'successiva »'}
            breakLabel="..."
            breakClassName={'break-me'}
            pageCount={Math.ceil(data.devices.totalCount / PAGE_LIMIT)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handleChangeOffset}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            initialPage={offset / PAGE_LIMIT}
          />
        )}
      </Container>
    </Layout>
  )
}

export default Devices
