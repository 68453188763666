import React, { useContext } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import AuthContext from './context/authContext'

import ProtectedRoute from './components/ProtectedRoute'
import Login from './pages/login/Login'
import Devices from './pages/Devices'
import Users from './pages/users/Users'
import Tasks from './pages/tasks/Tasks'
import Device from './pages/Device'

const Routes = () => {
  const auth = useContext(AuthContext)

  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <ProtectedRoute exact path="/" component={Devices} isAllowed={auth.isAuthenticated} redirectTo="/login" />
        <ProtectedRoute exact path="/devices" component={Devices} isAllowed={auth.isAuthenticated} redirectTo="/login" />
        <ProtectedRoute path="/devices/:id" component={Device} isAllowed={auth.isAuthenticated} redirectTo="/login" />
        <ProtectedRoute path="/users" component={Users} isAllowed={auth.isAuthenticated && auth.user.role === 'ADMIN'} redirectTo="/login" />
        <ProtectedRoute path="/tasks" component={Tasks} isAllowed={auth.isAuthenticated && auth.user.role === 'USER'} redirectTo="/login" />
      </Switch>
    </Router>
  )
}

export default Routes
