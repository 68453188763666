import { gql } from 'apollo-boost'

const CREATE_TASK = gql`
  mutation createTask($task: CreateTaskCustom!) {
    createTask(task: $task) {
      id
      deviceId
      plate
      device {
        machineId
        name
      }
      status
      notes
      description
      maxValue
      lockDisable
      startAt
      finishAt
    }
  }
`
export default CREATE_TASK
