import { gql } from 'apollo-boost'

const DEVICE_TYPES = gql`
  query deviceTypes {
    deviceTypes(limit: 10000) {
      nodes {
        id
        name
      }
    }
  }
`
export default DEVICE_TYPES
