import React, { useState, useEffect, useContext } from 'react'
import { useQuery } from 'react-apollo-hooks'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import DropdownItem from 'react-bootstrap/DropdownItem'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Badge from 'react-bootstrap/Badge'

import authContext from '../../context/authContext'

// Queries
import DEVICES from '../../graphql/queries/devices'

// Style
import './ModalTask.scss'

const ModalTask = ({ show, onCancel, onSend, values }) => {
  const auth = useContext(authContext)

  // Queries
  const {
    data: dataDevices,
    error: errorDevices,
    loading: loadingDevices,
  } = useQuery(DEVICES, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  })

  useEffect(() => {
    setFormValues(values)
  }, [values])

  const [formValues, setFormValues] = useState(values)

  const [labelDeviceField, setLabelDeviceField] = useState('Select a device')
  const [deviceFlag, setDeviceFlag] = useState(null)

  // calculated value
  const userFlag =
    dataDevices &&
    dataDevices.devices &&
    dataDevices.devices.nodes &&
    !!dataDevices.devices.nodes.filter((device) => device.deviceFlag !== 0)
      .length

  const handleChangeInput = (fieldName) => (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    setFormValues({
      ...formValues,
      [fieldName]: value,
    })
  }

  const handleSelectedDevice = (fieldObject) => (event) => {
    const newDeviceFlag = dataDevices.devices.nodes.find(
      (device) => device.id === fieldObject.id
    ).deviceFlag

    event.preventDefault()
    setLabelDeviceField(fieldObject.label)
    setDeviceFlag(newDeviceFlag)
    setFormValues({
      ...formValues,
      deviceId: fieldObject.id,
    })
  }

  const sendTask = (event) => {
    event.preventDefault()

    const valuesToSend = {
      ...formValues,
      maxValue:
        formValues.maxValue === true
          ? 1
          : formValues.maxValue === false
          ? 0
          : formValues.maxValue
          ? parseInt(formValues.maxValue)
          : undefined,
      lockDisable: formValues.lockDisable ? 1 : 0,
    }
    onSend({
      variables: {
        task: valuesToSend,
      },
    })
    setLabelDeviceField('Select a device')
    setDeviceFlag(null)
    onCancel()
  }

  const renderDevices = () => {
    if (loadingDevices || errorDevices) {
      return null
    }
    const items = dataDevices.devices.nodes

    return (
      <DropdownButton id="dropdown-item-button" title={labelDeviceField}>
        {items.map((item) => (
          <DropdownItem
            key={item.id}
            as="button"
            onClick={handleSelectedDevice({ id: item.id, label: item.name })}
          >
            {item.isAvailable ? (
              <React.Fragment>
                <span
                  className={`icon-is-available ${
                    item.isAvailable ? 'on' : ''
                  }`}
                />
                <span>{item.name}</span>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <span
                  className={`icon-is-available ${
                    item.isAvailable ? '' : 'off'
                  }`}
                />
                <span>{item.name}</span>
                <Badge variant="secondary" className="number-of-tasks">
                  {item.queuedTasksNumber > 0 ? item.queuedTasksNumber : ''}
                </Badge>
              </React.Fragment>
            )}
          </DropdownItem>
        ))}
      </DropdownButton>
    )
  }

  return (
    <Modal show={show} onHide={onCancel} className="create-task">
      <Form onSubmit={sendTask}>
        <Modal.Header closeButton>
          <Modal.Title>Add new task / Invia nuovo lavoro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Plate / Targa del veicolo</Form.Label>
            <Form.Control
              id="plate"
              type="text"
              value={formValues.plate}
              onChange={handleChangeInput('plate')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Device / Postazione</Form.Label>
            {renderDevices()}
          </Form.Group>
          <Form.Group>
            <Form.Label>Description / Descrizione lavoro</Form.Label>
            <Form.Control
              id="description"
              as="textarea"
              rows="3"
              value={formValues.description}
              onChange={handleChangeInput('description')}
            />
          </Form.Group>

          {userFlag && (
            <>
              {(deviceFlag === 1 || deviceFlag === 2 || deviceFlag === 8) && (
                <Form.Group>
                  <Form.Label>
                    Threshold / Altezza massima di sollevamento (mm)
                  </Form.Label>
                  <Form.Control
                    id="maxValue"
                    type="number"
                    min={100}
                    max={1770}
                    value={formValues.maxValue}
                    onChange={handleChangeInput('maxValue')}
                  />
                </Form.Group>
              )}
              {deviceFlag === 4 && (
                <Form.Group>
                  <Form.Check
                    id="maxValue"
                    type="checkbox"
                    className="mb-2"
                    label="Motorcycle height lifting / Sollevamento altezza moto"
                    checked={formValues.maxValue}
                    onChange={handleChangeInput('maxValue')}
                  />
                </Form.Group>
              )}
              {deviceFlag === 2 && (
                <Form.Group controlId="cbLockDisable">
                  <Form.Check
                    id="lockDisable"
                    type="checkbox"
                    className="mb-2"
                    label="Disable lowering autolock / Disabilita blocco in discesa"
                    checked={formValues.lockDisable}
                    onChange={handleChangeInput('lockDisable')}
                  />
                </Form.Group>
              )}
              {deviceFlag === 8 && (
                <Form.Group controlId="cbLockDisable">
                  <Form.Check
                    id="lockDisable"
                    type="checkbox"
                    className="mb-2"
                    label="Unlocking mechanical safety devices / Sblocco sicurezze meccaniche"
                    checked={formValues.lockDisable}
                    onChange={handleChangeInput('lockDisable')}
                  />
                </Form.Group>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Create task
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ModalTask
