import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

const ModalDeviceAdmin = ({ isEdit, show, onCancel, onSend, values }) => {
  const [formValues, setFormValues] = useState(values)

  useEffect(() => {
    setFormValues(values)
  }, [values])

  const handleChangeInput = fieldName => event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    setFormValues({
      ...formValues,
      [fieldName]: value,
    })
  }

  const sendDevice = event => {
    event.preventDefault()
    const deviceWithoutId = { ...formValues }
    delete deviceWithoutId.id
    delete deviceWithoutId.isOffline
    if (isEdit) { 
      onSend({ variables: { id: formValues.id, name: formValues.name, isOffline: formValues.isOffline } })
    } else { 
      onSend({ variables: deviceWithoutId })
    }
    onCancel()
  }

  return (
    <Modal show={show} onHide={onCancel}>
      <Form onSubmit={sendDevice}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEdit ? `Edit device ${formValues.name}` : 'Add device'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isEdit && (
            <React.Fragment>
              <Form.Group>
                <Form.Label>Machine Id</Form.Label>
                <Form.Control
                  id="machineId"
                  type="text"
                  value={formValues.machineId}
                  onChange={handleChangeInput('machineId')}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Token</Form.Label>
                <Form.Control
                  id="token"
                  type="text"
                  value={formValues.token}
                  onChange={handleChangeInput('token')}
                />
              </Form.Group>
            </React.Fragment>
          )}
          <Form.Group>
            <Form.Label>Name </Form.Label>
            <Form.Control
              id="name"
              type="text"
              value={formValues.name}
              onChange={handleChangeInput('name')}
            />
          </Form.Group>
          {isEdit && (
            <Form.Check
              id="isOffline"
              className="mb-2"
              label="Device Offline"
              checked={formValues.isOffline}
              onChange={handleChangeInput('isOffline')}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            {isEdit ? 'Edit device' : 'Add'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ModalDeviceAdmin
