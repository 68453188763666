import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const ProtectedRoute = ({ isAllowed, redirectTo, path, ...props }) =>
  isAllowed ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: redirectTo,
        from: props.location,
      }}
    />
  )
export default ProtectedRoute
