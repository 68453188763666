import React from 'react'

import { ApolloProvider } from 'react-apollo-hooks'
import apolloClient from './graphql/apolloClient'
import ApolloApp from './ApolloApp'

import './App.css'

const App = () => {
  

  return (
    <div className="app">
      <ApolloProvider client={apolloClient}>
        <ApolloApp />
      </ApolloProvider>
    </div>
  )
}

export default App
