import { useState } from 'react'

const useSearch = matchFields => {
  const [keywords, setKeywords] = useState('')

  const matching = device => {
    for (const field of matchFields) {
      const pathArray = field.split('.')
      const result = pathArray.reduce((curr, prop) => {
        return curr[prop]
      }, device)

      if (
        (result || result === 0) &&
        result.toLowerCase().indexOf(keywords.toLowerCase()) > -1
      ) {
        return true
      }
    }
    return false
  }

  return [keywords, setKeywords, matching]
}

export default useSearch
