import { gql } from 'apollo-boost'

const DELETE_USER = gql`
  mutation deleteUser($id: Int!) {
    deleteUser(id: $id) {
      id
      role
      name
      companyName
      username
      country
      city
      address1
      address2
      phone
      zipCode
      email
      devices {
        id
      }
      password
    }
  }
`
export default DELETE_USER
