import React from 'react'
import moment from 'moment'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Badge from 'react-bootstrap/Badge'
import { useQuery } from 'react-apollo-hooks'

import DEVICE_STATUS_LOG from '../graphql/queries/deviceStatusLog'

const DeviceStatusLog = ({ deviceId }) => {
  const from = moment()
    .subtract(2, 'months')
    .format('YYYY-MM-DD')
  const to = moment().format('YYYY-MM-DD')

  const { data: statusLogData, error: statusLogError, loading: statusLogLoading } = useQuery(DEVICE_STATUS_LOG, { variables: { deviceId, from, to } })

  const statuses = statusLogLoading || statusLogError || !statusLogData ? [] : statusLogData.getStats.find(s => s.name === 'status').values

  const modes = statusLogLoading || statusLogError || !statusLogData ? [] : statusLogData.getStats.find(s => s.name === 'mode').values

  return (
    <div>
      <Row>
        <Col sm="6">
          <h4
            style={{ textTransform: 'uppercase', fontSize: 16, padding: '4px 10px', backgroundColor: '#f0f0f0', borderRadius: 2, marginBottom: 10 }}
          >
            Last 2 Months: Status
          </h4>
          <Table style={{ fontSize: 13 }}>
            <thead>
              <tr>
                <th>From</th>
                <th>To</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {statuses.map(a => (
                <tr>
                  <td>{moment(a.date1).format('DD/MM/YYYY HH:mm')}</td>
                  <td>{moment(a.date2).format('DD/MM/YYYY HH:mm')}</td>
                  <td>
                    <Badge variant="success">{a.value}</Badge>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col sm="6">
          <h4
            style={{ textTransform: 'uppercase', fontSize: 16, padding: '4px 10px', backgroundColor: '#f0f0f0', borderRadius: 2, marginBottom: 10 }}
          >
            Last 2 Months: Mode
          </h4>
          <Table style={{ fontSize: 13 }}>
            <thead>
              <tr>
                <th>From</th>
                <th>To</th>
                <th>Mode</th>
              </tr>
            </thead>
            <tbody>
              {modes.map(a => (
                <tr>
                  <td>{moment(a.date1).format('DD/MM/YYYY HH:mm')}</td>
                  <td>{moment(a.date2).format('DD/MM/YYYY HH:mm')}</td>
                  <td>
                    <Badge variant="success">{a.value}</Badge>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  )
}

export default DeviceStatusLog
