import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-apollo-hooks'

import ME from './graphql/queries/me'
import AuthContext from './context/authContext'
import Routes from './Routes'

const ApolloApp = () => {
  // Query for authentication on page refresh
  const { loading, error, data } = useQuery(ME, { fetchPolicy: 'cache-and-network' })
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    user: {}
  })

  const setAuthenticated = (isAuthenticated, user) => {
    setAuth({
      isAuthenticated,
      user
    })
  }

  useEffect(() => {
    if (data && data.me) {
      setAuthenticated(true, data.me)
    }
  }, [data])

  const authContext = {
    ...auth,
    setAuthenticated
  }
  
  if (loading) {
    return null
  }

  return (
    <div>
      <AuthContext.Provider value={authContext}>
        <Routes />
      </AuthContext.Provider>
    </div>
  )
}

export default ApolloApp
