import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { withApollo } from 'react-apollo'
import { useQuery } from 'react-apollo-hooks'
import { LinkContainer } from 'react-router-bootstrap'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'
import { FiUsers, FiCpu, FiCheckSquare } from 'react-icons/fi'

import AuthContext from '../context/authContext'

import ME from '../graphql/queries/me'

const Layout = ({ children, history, client }) => {
  const auth = useContext(AuthContext)
  const { loading, error, data } = useQuery(ME)

  const logout = event => {
    event.preventDefault()
    //client.resetStore()
    auth.setAuthenticated(false, {})
    localStorage.removeItem('omcn_iot_token')
    history.push('/login')
  }

  return (
    <div>
      <style type="text/css">
        {`
        .navbar.bg-white {
          background-color: #fff;
          box-shadow: 0 0 30px rgba(28, 39, 60, 0.08);
          padding: 0;
          border-bottom: 1px solid rgba(28, 39, 60, 0.12);
        }
        .navbar.bg-white .nav-link {
          padding: 1rem 0;
          margin: 0 1.5rem;
        }
        .navbar.bg-white .nav-link.active {
          color: #228dcf;
          border-bottom: 2px solid #228dcf;
        }
        .navbar.bg-white .nav-link svg {
          margin-right: 5px;
        }
        `}
      </style>

      <Navbar bg="white" fixed="top" expand="lg">
        <Container>
          <LinkContainer to="/devices">
            <Navbar.Brand>
              <img src="http://www.omcn.it/wp-content/themes/omcn/assets/images/logo.png" alt="OMCN Devices Management App" style={{ height: 40 }} />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <LinkContainer to="/devices" activeClassName="active">
                <Nav.Link>
                  <FiCpu /> Devices
                </Nav.Link>
              </LinkContainer>

              {auth.user.role === 'ADMIN' ? (
                <LinkContainer to="/users" activeClassName="active">
                  <Nav.Link>
                    <FiUsers /> Users
                  </Nav.Link>
                </LinkContainer>
              ) : (
                <LinkContainer to="/tasks" activeClassName="active">
                  <Nav.Link>
                    <FiCheckSquare /> Tasks
                  </Nav.Link>
                </LinkContainer>
              )}
            </Nav>
            <Nav className="ml-auto">
              {loading ? null : (
                <NavDropdown title={data.me.name}>
                  <NavDropdown.Item href="" onClick={logout}>
                    Log out
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {children}
    </div>
  )
}

export default withRouter(Layout)
