import React, { useContext } from 'react'
import Button from 'react-bootstrap/Button'
import { FiPlusCircle } from 'react-icons/fi'

import AuthContext from '../context/authContext'

const CreateButton = ({ item, onClick }) => {
  const auth = useContext(AuthContext)
  const buttonText = auth.user.role === 'ADMIN' ? `Create ${item}` : `Add ${item}`

  return (
    <Button variant="primary" onClick={onClick}>
      <FiPlusCircle /> {buttonText}
    </Button>
  )
}

export default CreateButton
