import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'

const Search = ({ keywords, onChange }) => {
  return (
    <Form inline>
      <Form.Group>
        <Form.Control
          id="search"
          type="text"
          placeholder="Search..."
          style={{ borderRadius: 20, maxWidth: 200 }}
          value={keywords}
          onChange={onChange}
          className="mr-3"
        />
      </Form.Group>
    </Form>
  )
}

export default Search
