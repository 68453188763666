import { gql } from 'apollo-boost'

const DEVICE_HEIGHTS = gql`
  query getStats($deviceId: Int!, $from: DateTime!, $to: DateTime!) {
    getStats(deviceId: $deviceId, from: $from, to: $to, fields: [
      { name: "fcd", type: VALUE },
      { name: "height1", type: VALUE },
      { name: "height2", type: VALUE },
      { name: "height3", type: VALUE },
      { name: "height4", type: VALUE },
      { name: "height5", type: VALUE },
      { name: "height6", type: VALUE },
      { name: "height7", type: VALUE },
      { name: "height8", type: VALUE }
    ]) {
      name
      values {
        date1,
        value
      }
    }
  }
`
export default DEVICE_HEIGHTS
