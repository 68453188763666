import { gql } from 'apollo-boost'

const DELETE_TASK = gql`
  mutation deleteTask($id: Int!) {
    deleteTask(id: $id) {
      id
      deviceId
      plate
      device {
        machineId
        name
      }
      status
      notes
      description
      maxValue
      lockDisable
      startAt
      finishAt
    }
  }
`
export default DELETE_TASK
