import React from 'react'
import moment from 'moment'
import Table from 'react-bootstrap/Table'
import Badge from 'react-bootstrap/Badge'
import { useQuery } from 'react-apollo-hooks'

import DEVICE_ALARMS from '../graphql/queries/deviceAlarms'

const DeviceAlarms = ({ deviceId }) => {
  
  const from = moment()
    .subtract(2, 'months')
    .format('YYYY-MM-DD')
  const to = moment().format('YYYY-MM-DD')

  const { data: alarmsData, error: alarmsError, loading: alarmsLoading } = useQuery(DEVICE_ALARMS, { variables: { deviceId, from, to } })

  const alarmsFlat =
    alarmsLoading || alarmsError || !alarmsData
      ? []
      : alarmsData.getStats.reduce((acc, alarmType) => {
          const alarmEvents = alarmType.values.map(a => ({
            name: alarmType.name,
            ...a,
          })).filter(a => a.value !== '0' && a.value !== 'false' && a.value !== null)

          return [...acc, ...alarmEvents]
        }, []).sort((a,b)=> moment(a.date1).isBefore(moment(b.date1)) ? -1 : 1)

  return (
    <div>
      <h4 style={{ textTransform: 'uppercase', fontSize: 16, padding: '4px 10px', backgroundColor: '#f0f0f0', borderRadius: 2, marginBottom: 10 }}>
        Last 2 Months: Alarms
      </h4>
      <Table>
        <thead>
          <tr>
            <th>Started on</th>
            <th>Duration</th>
            <th>Type</th>
            <th>Code</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {alarmsFlat.map((a, index) => (
            <tr key={index}>
              <td>{moment(a.date1).format('DD/MM/YYYY HH:mm')}</td>
              <td>{moment.duration(moment(a.date1).diff(moment(a.date2))).humanize()}</td>
              <td>{a.name}</td>
              <td>
                <Badge variant="danger">{a.value}</Badge>
              </td>
              <td>-</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default DeviceAlarms
