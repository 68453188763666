import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

const ModalUser = ({ show, onCancel, onSend, isEdit, values }) => {
  const [formValues, setFormValues] = useState(values)

  useEffect(() => {
    setFormValues(values)
  }, [values])

  const handleChangeInput = fieldName => event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    setFormValues({
      ...formValues,
      [fieldName]: value,
    })
  }

  const sendUser = event => {
    event.preventDefault()
    const userWithoutId = { ...formValues }
    delete userWithoutId.id
    isEdit
      ? onSend({ variables: { user: userWithoutId, id: formValues.id } })
      : onSend({
          variables: {
            user: userWithoutId,
          },
        })
    onCancel()
  }

  return (
    <Modal show={show} onHide={onCancel}>
      <Form onSubmit={sendUser}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEdit ? `Edit user ${formValues.name}` : 'Add new User'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Role</Form.Label>
                <Form.Control
                  id="role"
                  as="select"
                  value={formValues.role}
                  onChange={handleChangeInput('role')}
                >
                  <option value="ADMIN">Admin</option>
                  <option value="USER">User</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Username</Form.Label>
                <Form.Control
                  id="username"
                  type="text"
                  value={formValues.username || ''}
                  onChange={handleChangeInput('username')}
                  disabled={isEdit ? true : false}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  id="name"
                  type="text"
                  value={formValues.name || ''}
                  onChange={handleChangeInput('name')}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Company</Form.Label>
                <Form.Control
                  id="companyName"
                  type="text"
                  value={formValues.companyName || ''}
                  onChange={handleChangeInput('companyName')}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Country</Form.Label>
                <Form.Control
                  id="country"
                  type="text"
                  value={formValues.country || ''}
                  onChange={handleChangeInput('country')}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>City</Form.Label>
                <Form.Control
                  id="city"
                  type="text"
                  value={formValues.city || ''}
                  onChange={handleChangeInput('city')}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  id="address1"
                  type="text"
                  value={formValues.address1 || ''}
                  onChange={handleChangeInput('address1')}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Address 2</Form.Label>
                <Form.Control
                  id="address2"
                  type="text"
                  value={formValues.address2 || ''}
                  onChange={handleChangeInput('address2')}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  id="phone"
                  type="text"
                  value={formValues.phone || ''}
                  onChange={handleChangeInput('phone')}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  id="zipCode"
                  type="text"
                  value={formValues.zipCode || ''}
                  onChange={handleChangeInput('zipCode')}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  id="email"
                  type="email"
                  value={formValues.email || ''}
                  onChange={handleChangeInput('email')}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  id="password"
                  type="password"
                  value={formValues.password || ''}
                  onChange={handleChangeInput('password')}
                />
              </Form.Group>
            </Col>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            {isEdit ? 'Edit user' : 'Create user'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ModalUser
