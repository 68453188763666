import { gql } from 'apollo-boost'

const USERS = gql`
  query users {
    users(limit: 10000) {
      nodes {
        id
        role
        name
        companyName
        username
        country
        city
        address1
        address2
        phone
        zipCode
        email
        devices {
          id
        }
        password
      }
    }
  }
`
export default USERS
