import { gql } from 'apollo-boost'

const DEVICE_BATTERIES = gql`
  query getStats($deviceId: Int!, $from: DateTime!, $to: DateTime!) {
    getStats(deviceId: $deviceId, from: $from, to: $to, fields: [
      { name: "battery1", type: VALUE },
      { name: "battery2", type: VALUE },
      { name: "battery3", type: VALUE },
      { name: "battery4", type: VALUE },
      { name: "battery5", type: VALUE },
      { name: "battery6", type: VALUE },
      { name: "battery7", type: VALUE },
      { name: "battery8", type: VALUE }
    ]) {
      name
      values {
        date1,
        value
      }
    }
  }
`
export default DEVICE_BATTERIES
