import React from 'react'
import moment from 'moment'

import { useQuery } from 'react-apollo-hooks'

import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

import DEVICE_HEIGHTS from '../graphql/queries/deviceHeights'
import DEVICE_ALARMS from '../graphql/queries/deviceAlarms'

const DeviceHeights = ({ deviceId, deviceType }) => {

  const from = moment()
    .subtract(2, 'months')
    .format('YYYY-MM-DD')
  const to = moment().format('YYYY-MM-DD')

  const { data: heightsData, error: heightsError, loading: heightsLoading } = useQuery(DEVICE_HEIGHTS, { variables: { deviceId, from, to } })

  const { data: alarmsData, error: alarmsError, loading: alarmsLoading } = useQuery(DEVICE_ALARMS, { variables: { deviceId, from, to } })

  const parseValue = (name, value) => {
    if (name !== 'fcd') {
      return parseInt(value, 10)
    }
    // Valori scambiati per vedere alto quando ponte in alto
    return value === 'true' ? 0 : 1
  }

  const series =
    heightsLoading || heightsError || !heightsData
      ? null
      : heightsData.getStats.filter(hs => {
        if (deviceType !== '995W' && hs.name !== 'fcd') {
          return false
        }
        if (deviceType === '995W' && hs.name === 'fcd') {
          return false
        }
        return true
      }).map(hs => ({
          name: hs.name,
          data: hs.values.map(h => [moment(h.date1).valueOf(), parseValue(hs.name, h.value)]),
        }))

  const plotBands =
    alarmsLoading || alarmsError || !alarmsData
      ? null
      : alarmsData.getStats.reduce((acc, alarmType) => {
          const alarmsOfType = alarmType.values.filter(alarm => alarm.value !== '0' && alarm.value !== 'false' && alarm.value !== null).map(alarm => ({
            color: 'hsla(36,100%,50%,0.4)',
            from: moment(alarm.date1, 'YYYY-MM-DD HH:mm:ss', true).valueOf(),
            to: moment(alarm.date2, 'YYYY-MM-DD HH:mm:ss', true).valueOf(),
            label: {
              text: `${alarm.value}`,
              style: {
                color: 'hsl(36,100%,30%)',
              },
            },
          }))

          return [...acc, ...alarmsOfType]
        }, [])

  const options = {
    time: {
      useUTC: false,
    },
    rangeSelector: {
      selected: 0,
      buttons: [
        {
          type: 'day',
          count: 1,
          text: '1d',
        },
        {
          type: 'month',
          count: 1,
          text: '1m',
        },
        {
          type: 'all',
          text: 'All',
        },
      ],
    },
    xAxis: {
      type: 'datetime',
      plotBands,
    },
    yAxis: [
      {
        title: {
          text: 'Heights',
        },
        lineWidth: 2,
      },
    ],
    series,
  }

  return (
    <div>
      <h4 style={{ textTransform: 'uppercase', fontSize: 16, padding: '4px 10px', backgroundColor: '#f0f0f0', borderRadius: 2, marginBottom: 10 }}>
        Heights: Last 2 months log
      </h4>
      {series && plotBands &&
        <HighchartsReact highcharts={Highcharts} constructorType={'stockChart'} options={options} />
      }
    </div>
  )
}

export default DeviceHeights
