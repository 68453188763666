import React, { useState, useContext } from 'react'
import { useQuery, useMutation } from 'react-apollo-hooks'
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import moment from 'moment'
import { FiTrash2, FiRefreshCcw, FiDownload } from 'react-icons/fi'
import SweetAlert from 'react-bootstrap-sweetalert'
import { CSVLink } from 'react-csv'

import Layout from '../../components/Layout'
import Title from '../../components/Title'
import Search from '../../components/Search'
import Heading from '../../components/Heading'
import CreateButton from '../../components/CreateButton'
import ModalTask from '../../components/Task/ModalTask'
import FromToDate from '../../components/FromToDate'

import authContext from '../../context/authContext'

// custom hooks
import useSearch from '../../customHooks/useSearch'

// Queries
import TASKS from '../../graphql/queries/tasks'
import DEVICES_WITH_THRESHOLD from '../../graphql/queries/devicesWithThreshold'

// Mutations
import CREATE_TASK from '../../graphql/mutations/createTask'
import DELETE_TASK from '../../graphql/mutations/deleteTask'

const todayStart = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')

const todayEnd = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')

const initialCreateTask = {
  deviceId: 0,
  plate: '',
  description: '',
  notes: '',
  maxValue: undefined,
  lockDisable: 0,
}

const Tasks = () => {
  const [keywords, setKeywords, match] = useSearch([
    'plate',
    'device.machineId',
    'device.name',
    'status',
  ])

  const [from, setFrom] = useState(todayStart)
  const [to, setTo] = useState(todayEnd)
  const [isSweetAlertOpen, setIsSweetAlertOpen] = useState(false)

  // Queries
  const {
    data: dataTasks,
    error: errorTasks,
    loading: loadingTasks,
    refetch: refetchTasks,
  } = useQuery(TASKS, {
    variables: {
      from: from,
      to: to,
    },
  })
  const {
    data: dataDevices,
    error: errorDevices,
    loading: loadingDevices,
  } = useQuery(DEVICES_WITH_THRESHOLD)

  const [task, setTask] = useState(initialCreateTask)
  const [taskToRemove, setTaskToRemove] = useState(null)
  // Modale
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => {
    setShow(false)
    setTask(initialCreateTask)
  }

  const auth = useContext(authContext)

  const handleSetKeywords = (event) => setKeywords(event.target.value)

  // Create Task
  const [createTask, { error: errorCreateTask, data: dataCreateTask }] =
    useMutation(CREATE_TASK, {
      // update: (cache, { data: { createTask } }) => {
      //   const { tasks } = cache.readQuery({
      //     query: TASKS,
      //     variables: { from, to }
      //   })
      //   const newTasks = {
      //     ...tasks,
      //     nodes: [...tasks.nodes, createTask]
      //   }
      //   cache.writeQuery({
      //     query: TASKS,
      //     variables: { from, to },
      //     data: { tasks: newTasks }
      //   })
      // },
      refetchQueries: ['devices', 'tasks'],
    })

  // Delete Task
  // Delete User
  const [deleteTask, { error: errorDeleteTask, data: dataDeleteTask }] =
    useMutation(DELETE_TASK, {
      update: (cache, { data: { deleteTask } }) => {
        const { tasks } = cache.readQuery({
          query: TASKS,
          variables: { from, to },
        })
        const newTasks = {
          ...tasks,
          nodes: tasks.nodes.filter((task) => task.id !== deleteTask.id),
        }
        cache.writeQuery({
          query: TASKS,
          variables: { from, to },
          data: { tasks: newTasks },
        })
      },
    })

  const handleConfirmDeleteTask = (taskId) => (event) => {
    setIsSweetAlertOpen(true)
    setTaskToRemove(taskId)
  }

  const handleDeleteTask = () => {
    deleteTask({ variables: { id: taskToRemove } })
    setIsSweetAlertOpen(false)
  }

  const handleCancelDeleteTask = () => {
    setIsSweetAlertOpen(false)
  }

  const handleDate = (event) => {
    const name = event.target.name
    const value = event.target.value
    name === 'from'
      ? setFrom(moment(value).startOf('day').format('YYYY-MM-DD HH:mm:ss'))
      : setTo(moment(value).endOf('day').format('YYYY-MM-DD HH:mm:ss'))
  }

  const handleCreateTask = (event) => {
    handleShow()
    setTask({
      ...initialCreateTask,
    })
  }

  const renderTasks = () => {
    if (loadingTasks || loadingDevices) {
      return <div>Caricamento tasks in corso...</div>
    }

    if (errorTasks || errorDevices) {
      return <div>Errore durante il caricamento dei tasks</div>
    }

    return (
      <Table striped>
        <thead>
          <tr>
            <th>Plate</th>
            <th>Task Description</th>
            <th>Device</th>
            <th>Status</th>
            <th>Notes</th>
            {dataDevices &&
              dataDevices.devices &&
              dataDevices.devices.totalCount && (
                <>
                  <th>Threshold</th>
                  <th>Autolock</th>
                </>
              )}
            <th>Start</th>
            <th>Finish</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {dataTasks.tasks.nodes.filter(match).map((task) => (
            <tr key={task.id}>
              <td>{task.plate}</td>
              <td>{task.description}</td>
              <td>
                {task.device.machineId} ({task.device.name})
              </td>
              <td>{task.status}</td>
              <td>{task.notes}</td>
              {dataDevices &&
                dataDevices.devices &&
                dataDevices.devices.totalCount && (
                  <>
                    <td>{task.maxValue}</td>
                    <td>
                      {task.device.deviceFlag !== 2 &&
                      task.device.deviceFlag !== 8
                        ? ''
                        : !!task.lockDisable
                        ? 'True'
                        : 'False'}
                    </td>
                  </>
                )}
              <td>{task.startAt}</td>
              <td>{task.finishAt}</td>
              <td className="delete-task">
                <FiTrash2 onClick={handleConfirmDeleteTask(task.id)} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  return (
    <Layout>
      <Container>
        <Title
          heading={<Heading title="Tasks" />}
          search={<Search keywords={keywords} onChange={handleSetKeywords} />}
          fromToDate={
            <FromToDate from={from} to={to} changeDate={handleDate} />
          }
          button={<CreateButton item="task" onClick={handleCreateTask} />}
        />
        <Button
          className="mb-3"
          onClick={() => refetchTasks()}
          disabled={loadingTasks || loadingDevices}
        >
          <FiRefreshCcw /> Refresh Tasks
        </Button>
        {dataTasks &&
          !loadingTasks &&
          !loadingDevices &&
          !errorTasks &&
          !errorDevices && (
            <CSVLink
              filename="export_omcn.csv"
              data={dataTasks.tasks.nodes.map((task) => ({
                Plate: task.plate,
                Description: task.description,
                DeviceID: task.device.machineId,
                DeviceName: task.device.name,
                Status: task.status,
                Notes: task.notes,
                Start: task.startAt,
                Finish: task.finishAt,
              }))}
            >
              <Button className="mb-3 ml-3">
                <FiDownload /> Export CSV
              </Button>
            </CSVLink>
          )}
        {renderTasks()}
      </Container>
      <ModalTask
        show={show}
        onCancel={handleClose}
        onSend={createTask}
        values={task}
      />
      <SweetAlert
        show={isSweetAlertOpen}
        warning
        showCancel
        confirmBtnText="Yes, remove"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Remove task?"
        onConfirm={handleDeleteTask}
        onCancel={handleCancelDeleteTask}
      >
        Are you sure you'd like to remove this task?
      </SweetAlert>
    </Layout>
  )
}

export default Tasks
