import React, { useState, useContext } from "react";
import { useMutation } from "react-apollo-hooks";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import AuthContext from "../../context/authContext";
import LOGIN from "../../graphql/mutations/login";

import "./Login.css";

const Login = ({ history, location }) => {
  const auth = useContext(AuthContext);

  const { from } = location;

  if (auth.isAuthenticated) {
    // Authenticated, no need to see the login page
    // Go to homepage or from is set from a redirect
    // and meanwhile it is authenticated
    history.push(from || "/");
  }

  const [values, setValues] = useState({
    username: "",
    password: "",
  });

  // const handleLoggedIn = ({ login }) => {
  //   localStorage.setItem('omcn_iot_token', login.token)
  //   history.push('/')
  // }

  const [login, { error, data }] = useMutation(LOGIN, {
    variables: { ...values },
    //onCompleted: handleLoggedIn,
  });

  const handleChangeInput = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleLogin = (event) => {
    event.preventDefault();

    login().then(
      (result) => {
        const {
          data: { login },
        } = result;
        localStorage.setItem("omcn_iot_token", login.token);
        auth.setAuthenticated(true, login.me);
        window.location.href = "/devices?v=1";
      },
      (error) => {
        // Reset username and password
        setValues({
          username: "",
          password: "",
        });
      }
    );
  };

  return (
    <div className="login">
      <div className="wrapper-form">
        <img
          src="http://www.omcn.it/wp-content/themes/omcn/assets/images/logo.png"
          alt="OMCN Devices Management App"
        />
        <Form onSubmit={handleLogin}>
          <Form.Group>
            <Form.Label>Username</Form.Label>
            <Form.Control
              id="username"
              type="text"
              name="username"
              value={values.username}
              onChange={handleChangeInput}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control
              id="password"
              type="password"
              name="password"
              value={values.password}
              onChange={handleChangeInput}
            />
          </Form.Group>
          <Button type="submit" block>
            Login
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Login;
