import { gql } from 'apollo-boost'

const UPDATE_DEVICE_ADMIN = gql`
  mutation updateDeviceAdmin($device: UpdateDeviceCustom!, $id: Int!) {
    updateDeviceAdmin(device: $device, id: $id) {
      id
      machineId
      name
      isOffline
      token
      lastContactDateTime
      user {
        id
        username
      }
      deviceType {
        id
        name
      }
    }
  }
`
export default UPDATE_DEVICE_ADMIN
