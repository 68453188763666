import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-apollo-hooks'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import { FiPlusCircle, FiEdit, FiTrash2 } from 'react-icons/fi'
import SweetAlert from 'react-bootstrap-sweetalert'

import Layout from '../../components/Layout'
import Title from '../../components/Title'
import Search from '../../components/Search'
import Heading from '../../components/Heading'
import CreateButton from '../../components/CreateButton'
import ModalUser from '../../components/User/ModalUser'

// custom hooks
import useSearch from '../../customHooks/useSearch'

// Queries
import USERS from '../../graphql/queries/users'
// Mutations
import CREATE_USER from '../../graphql/mutations/createUser'
import UPDATE_USER from '../../graphql/mutations/updateUser'
import DELETE_USER from '../../graphql/mutations/deleteUser'

import './Users.css'

const initialCreateUser = {
  role: 'USER',
  username: '',
  name: '',
  companyName: '',
  country: '',
  city: '',
  address1: '',
  address2: '',
  phone: '',
  zipCode: '',
  email: '',
  password: '',
}

const Users = () => {
  const {
    data: dataUsers,
    error: errorUsers,
    loading: loadingUsers,
  } = useQuery(USERS)

  const [keywords, setKeywords, match] = useSearch([
    'name',
    'companyName',
    'country',
    'city',
    'status',
    'phone',
  ])

  const [show, setShow] = useState(false)
  const [user, setUser] = useState(false)
  const [isSweetAlertOpen, setIsSweetAlertOpen] = useState(false)
  const [userToRemove, setUserToRemove] = useState(null)

  const handleSetKeywords = event => setKeywords(event.target.value)

  const [
    createUser,
    { error: errorCreateUser, data: dataCreateUser },
  ] = useMutation(CREATE_USER, {
    update: (cache, { data: { createUser } }) => {
      const { users } = cache.readQuery({ query: USERS })
      const newUsers = {
        ...users,
        nodes: [...users.nodes, createUser],
      }
      cache.writeQuery({
        query: USERS,
        data: { users: newUsers },
      })
    },
  })

  const [
    updateUser,
    { error: errorUpdateUser, data: dataUpdateUser },
  ] = useMutation(UPDATE_USER)

  // Delete User
  const [
    deleteUser,
    { error: errorDeleteUser, data: dataDeleteUser },
  ] = useMutation(DELETE_USER, {
    update: (cache, { data: { deleteUser } }) => {
      const { users } = cache.readQuery({ query: USERS })
      const newUsers = {
        ...users,
        nodes: users.nodes.filter(user => user.id !== deleteUser.id),
      }
      cache.writeQuery({
        query: USERS,
        data: { users: newUsers },
      })
    },
  })

  const handleConfirmDeleteUser = userId => event => {
    setIsSweetAlertOpen(true)
    setUserToRemove(userId)
  }

  const handleDeleteUser = () => {
    deleteUser({ variables: { id: userToRemove } })
    setIsSweetAlertOpen(false)
  }

  const handleCancelDeleteUser = () => {
    setIsSweetAlertOpen(false)
  }

  const getMutation = () => {
    if (user.id) {
      return updateUser
    } else {
      return createUser
    }
  }

  const handleShow = () => setShow(true)
  const handleClose = () => {
    setShow(false)
    setUser(initialCreateUser)
  }

  const handleCreateUser = () => {
    handleShow()
    setUser({
      ...initialCreateUser,
    })
  }

  const handleEditUser = user => event => {
    const params = {
      id: user.id,
      role: user.role,
      username: user.username,
      name: user.name,
      companyName: user.companyName,
      country: user.country,
      city: user.city,
      address1: user.address1,
      address2: user.address2,
      phone: user.phone,
      zipCode: user.zipCode,
      email: user.email,
      password: user.password,
    }
    event.stopPropagation()
    event.preventDefault()
    handleShow()
    setUser(params)

    // Query (variables: { id } )
  }

  const renderUsers = () => {
    if (loadingUsers) {
      return <div>Caricamento utenti in corso...</div>
    }

    if (errorUsers) {
      return <div>Errore avvenuto durante il caricamento degli utenti</div>
    }

    return (
      <Table striped>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Company</th>
            <th>Country</th>
            <th>City</th>
            <th>Devices</th>
            <th>Phone</th>
            <th>Username</th>
            <th>Password</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {dataUsers.users.nodes.filter(match).map(user => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.name}</td>
              <td>{user.companyName}</td>
              <td>{user.country}</td>
              <td>{user.city}</td>
              <td>{user.devices.length}</td>
              <td>{user.phone}</td>
              <td>{user.username}</td>
              <td>{user.password}</td>
              <td className="edit-user">
                <FiEdit onClick={handleEditUser(user)} />
              </td>
              <td className="delete-user">
                <FiTrash2 onClick={handleConfirmDeleteUser(user.id)} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  return (
    <Layout>
      <Container>
        <Title
          heading={<Heading title="Users" />}
          search={<Search keywords={keywords} onChange={handleSetKeywords} />}
          button={<CreateButton item="user" onClick={handleCreateUser} />}
        />
        {renderUsers()}
      </Container>
      <ModalUser
        show={show}
        onCancel={handleClose}
        isEdit={user.id}
        onSend={getMutation()}
        values={user || initialCreateUser}
      />
      <SweetAlert
        show={isSweetAlertOpen}
        warning
        showCancel
        confirmBtnText="Yes, remove"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Remove user?"
        onConfirm={handleDeleteUser}
        onCancel={handleCancelDeleteUser}
      >
        Are you sure you'd like to remove this user?
      </SweetAlert>
    </Layout>
  )
}

export default Users
