import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Card from 'react-bootstrap/Card'

import { FiEdit, FiArrowUp, FiArrowDown, FiCheckSquare, FiPower, FiAlertTriangle, FiRadio, FiWifi } from 'react-icons/fi'

import AuthContext from '../../context/authContext'

import './Device.scss'

const isError = currentState => {
  ;[1, 2, 3, 4, 5, 6, 7, 8].forEach(i => {
    if (currentState[`alarm${i}`]) {
      return true
    }
    if (currentState[`fault${i}`]) {
      return true
    }
  })
  return false
}

const isDisconnected = (currentState, lastContact) => {
  return !lastContact || moment(lastContact).isBefore(moment().subtract(4, 'hours'))
}

// const getStyle = (currentState, lastContact) => {
//   const error = []
//   const disconnected =
//     !lastContact || moment(lastContact).isBefore(moment().subtract(1, 'hours'))
//   if (error) {
//     return {
//       backgroundColor: 'hsl(346,100%,55%)',
//       color: '#fff',
//     }
//   }
//   if (disconnected) {
//     return {
//       backgroundColor: 'hsl(288,64%,92%)',
//       color: '#000',
//     }
//   }
//   return {
//     backgroundColor: 'white',
//     color: '#000',
//   }
// }

const DeviceThumbnail = ({
  device,
  // type,
  // isOffline,
  // isAvailable,
  // tasksNumber,
  // machineId,
  // name,
  // username,
  // currentState,
  // lastContact,
  onEdit,
}) => {
  const auth = useContext(AuthContext)
  const { id, machineId, deviceType, currentState, isOffline, name, user, lastContactDateTime: lastContact, queuedTasksNumber: tasksNumber } = device

  return (
    <div className="device">
      <Link to={`/devices/${id}`}>
        <Card>
          <div className="edit-icon" onClick={onEdit}>
            <FiEdit className="edit-device" />
          </div>

          <Card.Body className={isError(currentState) ? 'device-error' : ''}>
            <div className="indicators">
              <FiPower className={isOffline ? 'device-offline' : 'device-online'} />

              {currentState.fcd ? <FiArrowDown /> : <FiArrowUp />}

              {tasksNumber ? (
                <div className="tasks-numbers">
                  <FiCheckSquare />
                  <small>{tasksNumber}</small>
                </div>
              ) : null}
            </div>
            <Card.Title>{auth.user.role === 'ADMIN' ? machineId : name}</Card.Title>
            <Card.Text>
              {deviceType.name === '995W' &&
                <span>Status: <b>{currentState.status || 'N/A'}</b></span>
              }
              {/* <span>FCD: <b>{currentState.fcd ? '1' : '0'}</b></span> */}
            </Card.Text>

            <Card.Text style={{ borderTop: '1px solid rgba(0,0,0,0.2)', lineHeight: 1 }}>
              <small>User: {user ? user.username : ''}</small>
              <br />
              <small className={`last-contact ${isDisconnected(currentState, lastContact) ? 'device-disconnected' : ''}`}>
                Last update: {lastContact ? moment(lastContact).format('DD/MM/YYYY HH:mm') : '-'}
              </small>
            </Card.Text>
          </Card.Body>
        </Card>
      </Link>
    </div>
  )
}

export default DeviceThumbnail
