import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Title = ({ heading, search = null, fromToDate = null, button }) => {
  return (
    <section
      className="title"
      style={{
        paddingTop: 25,
        paddingBottom: 15,
        borderBottom: '1px solid #cdd4e0',
        marginBottom: 30,
      }}
    >
      <Row>
        <Col sm={fromToDate ? 3 : 6}>{heading}</Col>
        {fromToDate && <Col sm={5}>{fromToDate}</Col>}
        <Col sm={fromToDate ? 4 : 6}>
          <div className="d-flex justify-content-end">
            {search}
            {button}
          </div>
        </Col>
      </Row>
    </section>
  )
}

export default Title
