import { gql } from 'apollo-boost'

const ADD_DEVICE = gql`
  mutation addDevice($machineId: String!, $token: String!, $name: String) {
    addDevice(machineId: $machineId, token: $token, name: $name) {
      id
      machineId
      name
      isOffline
      token
      lastContactDateTime
      queuedTasksNumber
      isAvailable
      user {
        id
        username
      }
      deviceType {
        id
        name
      }
      currentState {
        status
        mode
        fcd
        height1
        height2
        height3
        height4
        height5
        height6
        height7
        height8
        battery1
        battery2
        battery3
        battery4
        battery5
        battery6
        battery7
        battery8
        alarm1
        alarm2
        alarm3
        alarm4
        alarm5
        alarm6
        alarm7
        alarm8
        fault1
        fault2
        fault3
        fault4
        fault5
        fault6
        fault7
        fault8
      }
    }
  }
`
export default ADD_DEVICE
